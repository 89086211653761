<template>
  <vx-card>
    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full mb-6">
        <ValidationProvider>
          <vs-input
            class="w-full"
            label="Nombre"
            v-model="account.name"
            placeholder="Ingresa usuario"
          >
          </vs-input>
        </ValidationProvider>
      </div>

      <div class="vx-col md:w-1/1 w-full mb-6">
        <ValidationProvider>
          <vs-input
            class="w-full"
            label="Password"
            v-model="account.password"
            placeholder="Ingrese contraseña"
          >
          </vs-input>
        </ValidationProvider>
      </div>
      <div class="vx-col w-full mb-6">
        <div class="flex flex-wrap justify-end">
          <vs-button @click="deleteAccount()" class="mr-3 mb-2" transparent
            >Eliminar</vs-button
          >
          <vs-button
            @click="$router.go(-1)"
            color="secondary"
            type="border"
            class="mb-2"
            >Descartar</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  data() {
    return {
      cajas: [],
      account: {
        name: '',
        password: '',
      },
    };
  },
  methods: {
    deleteAccount() {
      console.log('deleted');
    },
  },
};
</script>
